;(function($,window,undefined) {

	var $window = $(window);
	var $pagetop = $(".pagetop");
	var is_pagetop = false;

	var Common;

	Common = Common || {

			DEBUG: false,
			Page: null,
			BREAK_POINT_TAB: 900,
			BREAK_POINT_SP: 767,
			Window: {
				$window: null,
				$document: null,
				width: 0,
				height: 0
			},
			LAYOUT_MODE: "pc",
			current_layout_mode: "pc",
			user_agent: null,
			is_mobile: false,
			is_android: false,
			is_windows: false,
			is_mac: false,
			is_ie: false,
			is_ie6: false,
			is_ie7: false,
			is_ie8: false,
			is_ie9: false,
			is_ie10: false,
			is_ie11: false,
			is_chrome: false,
			is_safari: false,
			is_opera: false,
			is_firefox: false,
			is_edge: false,
			loaded_img_len: 0,
			img_len: 0,
			img_src_ary: [],
			is_intro_play: false, // イントロが用意されているか？
			is_intro_skip: false, // イントロをスキップするか？
			$body: null,
			timeout_timer: null, // 画像プリロードのタイムアウト用タイマー
			timeout_limit: 5000, // タイムアウトの時間


			$global: null,
			$global_btn: null,

			/* -----------------------------------------------
			 * 初回に一度だけ実行する
			 * ----------------------------------------------- */
			setupOnce: function(){
				Common.trace("func", "setupOnce");

				Common.$body = $("body");

				// ウィンドウ関連の情報をセット
				Common.Window.$window = $(window);
				Common.Window.$document = $(document);
				Common.Window.width = Common.Window.$window.width();
				Common.Window.height = Common.Window.$window.height();

				Common.$global = $(".global");
				Common.$global_btn = $(".globalBtnWrap");

				// URLクエリを取得
				Common.URL_QUERY = Common.getURLQuery();

				// ユーザーエージェント情報を取得、bodyにもクラス名として付与する
				Common.user_agent = Common.setUserAgent();

				// PC/SP のレイアウトモード判定
				if(Common.Window.width <= Common.BREAK_POINT_SP){
					Common.LAYOUT_MODE = "sp";
				}
				else{
					if(Common.user_agent.Mobile || Common.user_agent.Tablet){
						if(!Common.user_agent.IE){
							Common.LAYOUT_MODE = "sp";
						}
						else{
							Common.LAYOUT_MODE = "pc";
						}

					}else{
						Common.LAYOUT_MODE = "pc";
					};
				};

				Common.current_layout_mode = Common.LAYOUT_MODE;

				// Common.MenuEvent.init();
				Common.ResizeEvent.init();
				// Common.setHoverEvent(); // スマホのホバー制御
				Common.setGlobalMenu(); // スマホのホバー制御

				$("#blind .blind__loader").velocity("stop").velocity({opacity: 1},{ delay: 600, duration: 400 });
				Common.initPreLoader();

			},


			setGlobalMenu: function(){

				Common.$global_btn.on("click", Common.showGlobalMenu);

			},

			showGlobalMenu: function(){

				var $window = Common.Window.$window;

				if( Common.$global_btn.hasClass("is-open") ){
					Common.closeGlobalMenu();
					return;
				};

				Common.$global_btn.addClass("is-open");
				Common.$global.addClass("is-open");

				Common.$global
					.css({ opacity: 0 })
					.velocity("stop")
					.velocity({ opacity: 1 }, { duration: 300, easing: "easeOutQuart",
						complete: function(){
							Common.$global.css({ opacity: "" });
						}
					});

				TweenMax.fromTo(".globalMenu__item-home", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });
				TweenMax.fromTo(".globalMenu__item-news", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });
				TweenMax.fromTo(".globalMenu__item-member", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });
				TweenMax.fromTo(".globalMenu__sublist", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });
				TweenMax.fromTo(".globalMenu__item-contact", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });
				TweenMax.fromTo(".globalMenu__item-audition", .6, { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: 0, ease: Power3.easeOut });


				// スクロール禁止
				Common.$global.on('touchmove', function (e) {
					e.preventDefault();
					return false;
				});
				$window.on('mousewheel', function (e, delta, deltaX, deltaY) {
					e.preventDefault();
					return false;
				});

				// 背景クリックでもメニュー閉じる
				// $html_container.on("click", function(){
				// 	$(".global-btn").trigger("click");
				// })

			},

			closeGlobalMenu: function(){
				// alert("closeGlobalMenu");

				var $window = Common.Window.$window;

				Common.$global_btn.removeClass("is-open");
				$(".content__box").css({ overflow: "" });

				TweenMax.to(".global", .2, { opacity: 0, ease: Power2.easeOut,
					onComplete: function () {

						Common.$global.removeClass("is-open");
						Common.$global.removeAttr("style");
					}
				});

				// スクロール禁止 解除
				Common.$global.off('.noScroll');
				$window.off('mousewheel');

				// Common.$global
				// 	.velocity("stop")
				// 	.velocity({ opacity: 0 }, { duration: 300, easing: "easeOutQuart",
				// 	complete: function(){
				// 		Common.$global.removeClass("is-open");
				// 		Common.$global.css({ opacity: "" });
				// 	}
				// 	});

			},

			setHoverEvent: function(){

				//iOSだったら
				if (Common.user_agent.iOS) {
					$('a,.c-hover').bind({
						'touchstart': function() {
							$(this).addClass( 'hover' );
						},
						'touchend': function() {
							$(this).removeClass( 'hover' );
						}
					});
				}
				//iOS以外だったら
				else {
					$('a,.c-hover').bind({
						'mouseover': function() {
							$(this).addClass( 'hover' );
						},
						'mouseout': function() {
							$(this).removeClass( 'hover' );
						}
					});
				}
			},

			initPreLoader: function(){
				Common.trace("Common -> initPreLoader()");

				this.img_len = $('img').length;

				Common.timeout_timer = setTimeout(function(){
					Common.hidePreLoader();
				}, Common.timeout_limit);

				if(this.img_len > 0) this.setPreLoader();
				else this.hidePreLoader();
			},

			setPreLoader: function(){
				var _this = this;

				Common.trace('PRELOADER', 'initLoad');

				// IE8の場合はすぐにトップ表示に移る
				// if(Common.$body.hasClass("ie8")){
				// 	hidePreLoader();
				// 	return;
				// };

				for(var i = 0; i < this.img_len; i++){
					var _src = $('img:eq(' + i + ')').attr('src');
					_this.img_src_ary.push(_src);
					// Common.trace(_src);
				};

				$(document).smartpreload({
					images: _this.img_src_ary,
					oneachimageload: function(src) {
						_this.loaded_img_len++;
						Common.onLoadUpdate();
					},
					onloadall: function() {
						Common.trace('PRELOADER :: All item is loaded.');
						Common.hidePreLoader();
					}
				});
			},

			onLoadUpdate: function(){
				var _this = this;

				Common.trace('PRELOADER :: ' + _this.loaded_img_len + '/' + _this.img_src_ary.length + ' Loaded');
				// Common.trace('PRELOADER :: ' + _per + '% Loaded');

				if (_this.loaded_img_len == _this.img_src_ary.length){
					Common.trace('PRELOADER :: All item is loaded.');
				};
			},


			/* -----------------------------------------------
			 * PRE LOADER ANIMATION
			 * ----------------------------------------------- */
			showPreLoader: function(){
				Common.trace('Common -> showPreloader');
				Common.initPreLoader();
			},

			hidePreLoader: function(){
				Common.trace('Common -> hidePreLoader');

				if(!Common.timeout_timer){
					return;
				};

				this.init();
			},

			/* -----------------------------------------------
			 * 初期化
			 * ----------------------------------------------- */
			reload_count: 0,
			reload_count_max: 2,
			init: function(){
				Common.trace("Common -> init()");

				clearTimeout(Common.timeout_timer);
				Common.timeout_timer = null;

				if(!Common.Page && this.reload_count < this.reload_count_max){
					this.reload_count++;
					setTimeout(Common.init, 500);
				};

				Common.trace(Common.Page);
				if(Common.Page) {
					setTimeout(function(){
						Common.Page.setupOnce();
					}, 30);
				}

				// イントロが用意されている、かつトップページの場合は終了
				if( Common.is_intro_play && $("#page-home").length > 0 ) return;

				pageFadeIn();

			},

			/* -----------------------------------------------
			 * メニュー関連の処理
			 * ----------------------------------------------- */
			MenuEvent: {
				init: function(){
					Common.trace("MenuEvent -> init()");

					var _this = this;
					var _height = 0;
					var _in_anim = false;
					var _is_menu_open = false;
					var $html_container = $(".html-container");
					var $global_bg = $(".global-bg");
					var $global_area_wrap = $(".global-area-wrap");
					var $global_area = $(".global-area");
					var $window = Common.Window.$window;


					$(".global-btn").on("click", function(){

						var $this = $(this);

						if( $this.hasClass("is-active") ){
							$this.removeClass("is-active");
							$this.removeClass("is-active");
							$html_container.removeClass("is-active");
							$global_bg.removeClass("is-active");
							$global_area.removeClass("is-active");
							// $global_area_wrap.removeClass("is-active");

							// スクロール禁止 解除
							$global_area.off('.noScroll');
							$window.off('mousewheel');
							$html_container.off("click");

						}else{
							$this.addClass("is-active");
							$html_container.addClass("is-active");
							$global_bg.addClass("is-active");
							$global_area.addClass("is-active");
							// $global_area_wrap.addClass("is-active");

							// スクロール禁止
							$global_area.on('touchmove', function (e) {
								e.preventDefault();
								return false;
							});
							$window.on('mousewheel', function (e, delta, deltaX, deltaY) {
								e.preventDefault();
								$window.off('mousewheel');
								$(".global-btn").trigger("click");
								return false;
							});
							$html_container.on("click", function(){
								$(".global-btn").trigger("click");
							})
						}

					});

				}
			},

			/* -----------------------------------------------
			 * リサイズイベント
			 * ----------------------------------------------- */
			ResizeEvent: {
				vsl_ratio: 1280 / 720,
				window_ratio: 0,
				bg_offset: 0,
				init: function(){
					// console.log("ResizeEvent -> init()");

					// console.log(Common.Window.$window);
					// Common.Window.$window.off("resize");
					Common.Window.$window.on("resize", Common.ResizeEvent.onResize).trigger("resize");

				},
				onResize: function(e){
					Common.trace("Common -> ResizeEvent -> onResize()");

					Common.Window.width = Common.Window.$window.width();
					Common.Window.height = Common.Window.$window.height();
					// _this.window_ratio = _movie_width / _movie_height;

					if(Common.Window.width <= Common.BREAK_POINT_SP){
						Common.LAYOUT_MODE = "sp";
					}
					else{
						if(Common.user_agent.Mobile || Common.user_agent.Tablet){
							if(!Common.user_agent.IE){
								Common.LAYOUT_MODE = "sp";
							}
							else{
								Common.LAYOUT_MODE = "pc";
							}

						}else{
							Common.LAYOUT_MODE = "pc";
						};
					};

					if( Common.LAYOUT_MODE != Common.current_layout_mode ){
						location.reload();
						return;
					};

				}
			},

			/* -----------------------------------------------
			 * URLクエリの取得
			 * ----------------------------------------------- */
			getURLQuery: function () {
				var url = location.href;
				var parameters = url.split("?");
				if (parameters.length < 2) return false;
				params = parameters[1].split("&");
				var paramsArray = [];
				for (i = 0; i < params.length; i++) {
					neet = params[i].split("=");
					paramsArray.push(neet[0]);
					paramsArray[neet[0]] = neet[1];
				}
				// var categoryKey = paramsArray[opt_key];
				return paramsArray;
			},

			/* -----------------------------------------------
			 * ユーザーエージェントの取得
			 * bodyタグにエージェント名をクラスとして付与する
			 * ----------------------------------------------- */
			setUserAgent: function(){
				Common.trace("Common -> setUserAgent()");

				var ua = window.navigator.userAgent.toLowerCase();
				var ver = window.navigator.appVersion.toLowerCase();
				var name = 'unknown';

				if( window.navigator.platform.indexOf("Win") != -1 ){
					Common.is_windows = true;
				}
				else if(ua.match(/Mac|PPC/)){
					Common.is_mac = true;
				};

				if (ua.indexOf("msie") != -1){
					Common.$body.addClass("ie");
					if (ver.indexOf("msie 6.") != -1){
						name = 'ie6';
						Common.is_ie6 = true;
					}else if (ver.indexOf("msie 7.") != -1){
						name = 'ie7';
						Common.is_ie7 = true;
					}else if (ver.indexOf("msie 8.") != -1){
						name = 'ie8';
						Common.is_ie8 = true;
					}else if (ver.indexOf("msie 9.") != -1){
						name = 'ie9';
						Common.is_ie9 = true;
					}else if (ver.indexOf("msie 10.") != -1){
						name = 'ie10';
						Common.is_ie10 = true;
					}else{
						name = 'ie';
						Common.is_ie = true;
					}
				}else if(ua.indexOf('trident/7') != -1){
					name = 'ie11';
					Common.$body.addClass("ie");
					Common.is_ie11 = true;
					Common.is_ie = true;
				}else if (ua.indexOf('chrome') != -1){
					name = 'chrome';
					Common.is_chrome = true;
				}else if (ua.indexOf('safari') != -1){
					name = 'safari';
					Common.is_safari = true;
				}else if (ua.indexOf('opera') != -1){
					name = 'opera';
					Common.is_opera = true;
				}else if (ua.indexOf('firefox') != -1){
					name = 'firefox';
					Common.is_firefox = true;
				};
				if (ua.indexOf('edge') != -1){
					name = 'edge';
					Common.is_edge = true;
					Common.$body.addClass("edge");
				};

				Common.$body.addClass(name);
				if(ua.indexOf("mobile") != -1){
					Common.$body.addClass("mobile");
					Common.is_mobile = true;
				};
				if(ua.indexOf("android") != -1){
					Common.$body.addClass("android");
					Common.is_android = true;
				};


				return (function(u){

					var _is_ie = (u.indexOf('trident/7') > -1) || (u.indexOf('msie') > -1) && (u.indexOf('opera') == -1);

					return {
						Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1)
						|| u.indexOf("ipad") != -1
						|| (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
						|| (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
						|| u.indexOf("kindle") != -1
						|| u.indexOf("silk") != -1
						|| u.indexOf("playbook") != -1,
						Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
						|| u.indexOf("iphone") != -1
						|| u.indexOf("ipod") != -1
						|| (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
						|| (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
						|| u.indexOf("blackberry") != -1,
						Android: (u.indexOf("android") != -1),
						iOS:(u.indexOf("iphone") != -1)
						|| u.indexOf("ipod") != -1
						|| u.indexOf("ipad") != -1,
						IE: _is_ie,
						IE_VERSION: ( !_is_ie )? -1 : parseInt( u.match(/((msie|MSIE)\s|rv:)([\d\.]+)/)[3] ),
						Chrome: (u.indexOf('chrome') > -1) && (u.indexOf('edge') == -1),
						Firefox: (u.indexOf('firefox') > -1),
						Safari: (u.indexOf('safari') > -1) && (u.indexOf('chrome') == -1),
						Opera: (u.indexOf('opera') > -1),
						Edge: (u.indexOf('edge') > -1)
					}
				})(ua);

			},

			trace: function( args ){
				if(!Common.DEBUG) return;
				// if (jQuery.browser.msie) {return};
				console.log("[trace]" + args);
			}

		};


	// 戻るボタンで起動しない問題の対応処理
	window.onpageshow = function(event) {
		Common.trace("戻るボタンによる再読込: 判定")
		if (event.persisted) {
			Common.trace("戻るボタンによる再読込: リロード")
			window.location.reload();
			return;
		};
	};

	Common.setupOnce();
	window.Common = Common;


	/* -----------------------------------------------
	 * スクロールイベント
	 * ----------------------------------------------- */
	var is_scroll = false;
	var is_sp_scroll = false;
	var pagetop_fix_y = 0;
	var is_pagetop_fixed = false;
	var $footer = $(".footer");
	var footer_offset = $footer.offset().top;
	var document_h = Math.max.apply( null, [document.body.clientHeight , document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight] );
	var is_no_pagetop = Boolean(document_h > Common.Window.height);

	// alert("document_h: " + document_h);
	// alert("Common.Window.height: " + Common.Window.height);

	$window.on('scroll', function (e) {

		document_h = Math.max.apply( null, [document.body.clientHeight , document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight] );
		is_no_pagetop = Boolean(document_h > Common.Window.height);

		// console.log("document_h: " + document_h);
		// console.log("Common.Window.height: " + Common.Window.height);

		if(!is_no_pagetop) return;

		var _h 		= Common.Window.height,
			// _sTop   = window.pageYOffset,
			_sTop   = document.body.scrollTop || document.documentElement.scrollTop,
			_sMdl   = _sTop + _h / 2,
			_sBtm   = _sTop + _h;

		footer_offset = $footer.offset().top;


		/* -----------------------------------------------
		 * ページトップの表示/非表示
		 * ----------------------------------------------- */
		if (_sTop <= 10) {
			if (!is_pagetop) return;
			is_pagetop = false;
			$pagetop.removeClass("is-scroll");
		} else if (_sTop > 10) {

			document_h = Math.max.apply( null, [document.body.clientHeight , document.body.scrollHeight, document.documentElement.scrollHeight, document.documentElement.clientHeight] );
			// pagetop_fix_y = document_h - footer_h;
			// pagetop_fix_y = footer_offset;

			if(!is_pagetop_fixed && _sBtm >= pagetop_fix_y){

				is_pagetop_fixed = true;
				$pagetop.addClass("is-stop");
			}
			else if(is_pagetop_fixed && _sBtm < pagetop_fix_y){
				is_pagetop_fixed = false;
				$pagetop.removeClass("is-stop");
			}
			if (is_pagetop) return;

			is_pagetop = true;
			$pagetop.addClass("is-scroll");

		};


	});



	/* -----------------------------------------------
	 * ページトップ　スクロール
	 * ----------------------------------------------- */
	$pagetop.on('click', function(e){
		if(e) e.preventDefault();
		autoScroll('html', 0, 1200, "easeInOutQuart");
	});


	/* -----------------------------------------------
	 * ページ フェード・アウト
	 * ----------------------------------------------- */
	$("a:not([href^='#'])").on('click', function(e){

		if($(this).hasClass("no-fade")){
			e.preventDefault();
			return;
		};

		var $this = $(this);

		if($this.attr('target') == "_blank"){
			return;
		};

		e.preventDefault();

		$('#blind')
			.show()
			.css({ opacity: 0 })
			.velocity({ opacity: 1 },{
				duration: 380,
				easing: 'easeOutCirc',
				complete: function(){
					_href = $this.attr('href')
					window.location = _href;
					target = "_self";

					if( $(".page-member").length && _href.match(/\/member\/#/) ){
						window.location.reload();
					};
				}
			});

	});

	/* -----------------------------------------------
	 * ページ フェード・イン
	 * ----------------------------------------------- */
	var is_anim = true;
	var $blind = $('#blind');

	function pageFadeIn(){
		Common.trace("pageFadeIn()");

		window.scrollTo(0,0);

		$blind.find(".loader").velocity("stop").velocity({opacity: 0},{duration: 200});
		$blind
			.velocity({
				opacity: 0
			},{
				delay: (is_anim)? 300 : 0,
				duration: (is_anim)? 600 : 0,
				easing: 'easeOutQuart',
				complete: function(){
					$blind.hide();
					$blind.find(".loader").remove();
				}
			});
	}

})(jQuery,window);



/* -----------------------------------------------
 * オートスクロール
 * ----------------------------------------------- */
function autoScroll(opt_hash, opt_delay, opt_duration, opt_easing, opt_offset){

	var target_pos;
	var $html = $("html,body");

	if(opt_hash == '#'){
		return;
	}
	if(!opt_offset){
		opt_offset = 0;
	}

	target_pos = $(opt_hash).offset().top;
	// target_pos-= parseInt($menu.height()) + 10;

	var	target = $(opt_hash === "#" || opt_hash === "" ? 'html' : opt_hash);
	target.velocity("scroll", { duration: opt_duration, easing: opt_easing, offset: opt_offset * -1 });

};


//
//
// function trace(opt_str){
// 	//if (jQuery.browser.msie) {return};
// 	//console.log(opt_str);
// };
//


